import myhttp from './http'

//获取所有的用户
export function getAllUserInfo(info) {
    return myhttp({
        url: "/user/selectUserInfo",
        method: "POST",
        params: info
    });
}
//根据id获取一个用户
export function getListByWaAdminBy(id) {
    return myhttp({
        url: "/hring/getListByWaAdminBy",
        method: "GET",
        params: { id }
    });
}
//根据id获取一个用户
export function getUserInfoById(id) {
    return myhttp({
        url: "/user/selectUserInfoByIdBg",
        method: "POST",
        params: { id }
    });
}
//新增一个用户
export function addUserInfo(form) {
    return myhttp({
        url: "/user/addUserInfoBg",
        method: "POST",
        data:  form 
    });
}
//修改一个用户
export function updateUserInfo(form) {
    return myhttp({
        url: "/user/updateUserInfoBg",
        method: "POST",
        data:  form 
    });
}

//删除一个用户
export function deleteUserInfo(id) {
    return myhttp({
        url: "/user/deleteUserInfo",
        method: "POST",
        params: { id }
    });

}


//获取所有的角色类型
export function getAllTypes(page) {
    return myhttp({
        url: "/types/selectTypesBg",
        method: "GET",
        params: { page }
    });
}




//根据用户id查询参加听证信息
export function selectByUserid(info) {
    return myhttp({
        url: "/hringatted/selectByUserid",
        method: "POST",
        data: info
    });
}
